/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from '@reach/router'

BasicPostLink.propTypes = {
    props: PropTypes.object,
}
export function BasicPostLink(props) {
    function GetLinkTarget(){
        if (typeof props.target !== "string"){
            return "_blank"
        }
        if (!props.target) {
            return "_blank";
        }
        if (props.target.trim() === "") {
            return "_blank";
        }

        return props.target.trim();
    }
    return (
        <a href={props.href} target={GetLinkTarget()} title={props.title} aria-label={props.title}>{props.title}</a>
    )
}




