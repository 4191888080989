import React, { useState } from "react";
import PropTypes from "prop-types";

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Lightbox from 'react-image-lightbox';
import Image from 'material-ui-image'



ImageGridList.propTypes = {
    props: PropTypes.object,
}



export default function ImageGridList({ children, pageNode, ...props }) {
    
    let [imageIndex, setImageIndex] = useState(0);
    let [lightboxOpen, setLightboxOpen] = useState(false);
    function ProcessImageClick(index){
        imageIndex = index;
        setImageIndex(index)
        if (!lightboxOpen){
            lightboxOpen = true
            setLightboxOpen(true);
        }

    }


    return (
        <GridList cellHeight={180}>
            {props.images.map((image, index) => (
                <GridListTile key={index}>
                    <Image src={image.image.publicURL} alt={image.title} title={image.title} onClick={() => ProcessImageClick(index)} />
                </GridListTile>
            ))}
            {lightboxOpen && (
                <Lightbox
                    mainSrc={props.images[imageIndex].image.publicURL}
                    nextSrc={props.images[(imageIndex + 1) % props.images.length]}
                    prevSrc={props.images[(imageIndex + props.images.length - 1) % props.images.length]}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() => setImageIndex((imageIndex + props.images.length - 1) % props.images.length) }
                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % props.images.length)}
                />
            )}
        </GridList>
    )
}