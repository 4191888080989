import React from "react"

import PropTypes from "prop-types"
import { Link } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

// MDX Components
import { H1, H2, H3, H4, H5, H6 } from "@components/header/header.js"
import Button from "@components/customButtons/button.js"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import ImageGridList from "@components/layout/section/common/image/gridList.js"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import CodeBlock from "@components/layout/section/common/page/codeBlock.js"
import { BasicPostLink } from "@components/link/basicPostLink.js"

// Provide common components here
const shortcodes = {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ImageGridList,
  GridList,
  GridListTile,
  GridListTileBar,
  GridContainer,
  GridItem,
  Button,
  Link,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Typography,
  CodeBlock,
  BasicPostLink,
}

ThreeMysticApesMDXRenderer.propTypes = {
  pageNode: PropTypes.object.isRequired,
}

export default function ThreeMysticApesMDXRenderer({ contentBody, ...props }) {
  return (
    <MDXProvider components={shortcodes}>
      <MDXRenderer {...props}>{props.pageContext.body}</MDXRenderer>
    </MDXProvider>
  )
}
